export const LOGIN_PATH = "login";
export const DASHBOARD_PATH = "dashboard";
export const PROFILE_PATH = "profile";
export const QUOTE_PATH = "quote";
export const USERS_PATH = "users";
export const SHIPMENT_PATH = "shipment";
export const COMPANIES_PATH = "companies";
export const QUOTE_REQUEST_PATH = "request";
export const USER_QUOTE_LIST_PATH = "user-list";
export const COMPANIES_LIST_PATH = "companies-list";
export const COMPANIES_LIST_QUOTE_REQUEST = "companies-list-create-quote";
export const USER_QUOTE_DETAIL_PATH = "user-detail";
export const QUOTE_LIST_PATH = "list";
export const QUOTE_DETAIL_PATH = "detail";
export const USER_SHIPMENT_LIST_PATH = "user-list";
export const USER_SHIPMENT_DETAIL_PATH = "user-detail";
export const SHIPMENT_LIST_PATH = "list";
export const SHIPMENT_DETAIL_PATH = "detail";
export const SHIPMENT_WORK_SHEET = "work-sheet";
export const MARITIME_PATH = "maritime-shipping";
export const AIR_SHIPPING_PATH = "air-shipping";
export const GROUND_SHIPPING_PATH = "ground-shipping";
export const SELECTOR_PATH = "selector";
export const LOAD_GENERATOR_PATH = "load-generator";
export const INVITE_LOAD_GENERATOR_PATH = "invite_load-generator";
export const PRICING_PATH = "pricing";
export const MOBILE_FIX = "mobile-suggestion";

//External Filenames
export const CONFIDENT_AGREED_FILE = "acuerdo_seguridad_v1.pdf";
export const DATA_TREATMENT_FILE = "politica_tratamiento_data_V0.pdf";
export const DATA_NOTICE_FILE = "aviso_tratamiento_de_datos_V0.pdf";
export const TERMS_AND_CONDITIONS = "terminos_y_condiciones_cliente_V0.pdf";

export const DASHBOARD_URL = `/${DASHBOARD_PATH}`;
export const PROFILE_URL = `/${DASHBOARD_PATH}/${PROFILE_PATH}`;
export const USERS_LOAD_GENERATOR_LIST_PATH = `/${DASHBOARD_PATH}/${USERS_PATH}/${LOAD_GENERATOR_PATH}`;
export const USERS_INVITE_LOAD_GENERATOR_LIST_PATH = `/${DASHBOARD_PATH}/${USERS_PATH}/${INVITE_LOAD_GENERATOR_PATH}`;
export const USERS_PRICING_LIST_PATH = `/${DASHBOARD_PATH}/${USERS_PATH}/${PRICING_PATH}`;

export const QUOTE_ROOT_URL = `/${DASHBOARD_PATH}/${QUOTE_PATH}`;
export const QUOTE_REQUEST_ROOT_URL = `/${DASHBOARD_PATH}/${QUOTE_PATH}/${QUOTE_REQUEST_PATH}`;

export const QUOTE_LIST_URL = `/${QUOTE_ROOT_URL}/${QUOTE_LIST_PATH}`;
export const QUOTE_REQUEST_DETAIL = (id: string) =>
  `${QUOTE_REQUEST_ROOT_URL}/${id}/${QUOTE_DETAIL_PATH}`;
export const USER_QUOTE_LIST_URL = `/${QUOTE_ROOT_URL}/${USER_QUOTE_LIST_PATH}`;
export const COMPANY_LIST_URL = `/${DASHBOARD_PATH}/${COMPANIES_PATH}/${COMPANIES_LIST_PATH}`;

export const USER_CREATE_QUOTE_REQUEST_URL = `/${QUOTE_REQUEST_ROOT_URL}/${SELECTOR_PATH}`;
export const USER_QUOTE_REQUEST_MARITIME_URL = `/${QUOTE_REQUEST_ROOT_URL}/${MARITIME_PATH}`;
export const USER_QUOTE_REQUEST_AIR_SHIPPING_URL = `/${QUOTE_REQUEST_ROOT_URL}/${AIR_SHIPPING_PATH}`;
export const USER_QUOTE_REQUEST_GROUND_URL = `/${QUOTE_REQUEST_ROOT_URL}/${GROUND_SHIPPING_PATH}`;
export const USER_QUOTE_REQUEST_DETAIL = (id: string) =>
  `${QUOTE_REQUEST_ROOT_URL}/${id}/${USER_QUOTE_DETAIL_PATH}`;

// Shipments
export const SHIPMENT_ROOT_URL = `/${DASHBOARD_PATH}/${SHIPMENT_PATH}`;

export const SHIPMENT_LIST_URL = `${SHIPMENT_ROOT_URL}/${SHIPMENT_LIST_PATH}`;
export const SHIPMENT_DETAIL = (id: string) =>
  `${SHIPMENT_ROOT_URL}/${id}/${SHIPMENT_DETAIL_PATH}`;

export const SHIPMENT_WORK_SHEET_OPERATIONS = `/${DASHBOARD_PATH}/${SHIPMENT_PATH}/${SHIPMENT_WORK_SHEET}`;

export const USER_SHIPMENT_LIST_URL = `${SHIPMENT_ROOT_URL}/${USER_SHIPMENT_LIST_PATH}`;
export const USER_SHIPMENT_DETAIL = (id: string) =>
  `${SHIPMENT_ROOT_URL}/${id}/${USER_SHIPMENT_DETAIL_PATH}`;

// Create quotes by commercial table
export const TABLE_COMPANIES_QUOTE_REQUEST = `/${DASHBOARD_PATH}/${COMPANIES_PATH}/${COMPANIES_LIST_QUOTE_REQUEST}`;
