import { ApiSelector } from "../../../../libs/enums/api-selector";

export const environment = {
  production: true,
  [ApiSelector.AUTH]: "https://release.kargoru.com/neo/auth/api-auth",
  [ApiSelector.BUSINESS_LOGIC]:
    "https://release.kargoru.com/neo/quotes/quote-app",
  [ApiSelector.COMMON]: "https://release.kargoru.com/neo/common-tools/api",
  [ApiSelector.PRICING]: "https://release.kargoru.com/neo/pricing_api/pricing-service",
  logApiUrl: "https://rovistack.herokuapp.com/api/logs",
  appKey: "uhcsEb7e2J4yQDnQ7cWK5jravtehVgpfKl9ybIhrvSRJeDDN8RAsUmpFDtl377GY",
  appId: "5e9cbe9432767b0004d70356",
  documentsBaseUrl: "https://static-assets-kargoru.s3.amazonaws.com",
};

export const APP_PROD = true;

export type Environment = typeof environment;
