import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kargoru-mobile-fix',
  templateUrl: './mobile-fix.component.html',
  styleUrls: ['./mobile-fix.component.scss']
})
export class MobileFixComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
