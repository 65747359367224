import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { NxModule } from '@nrwl/angular';

import { CoreModule } from 'apps/kargoru-web/src/app/core/core.module';
import { environment, APP_PROD } from '../environments/environment';
import { AppComponent } from './app.component';
import { ROOT_REDUCERS } from 'apps/kargoru-web/src/app/store/reducers';
import { effects } from 'apps/kargoru-web/src/app/store/effects';
import { AppRoutingModule } from 'apps/kargoru-web/src/app/app-routing.module';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(environment),
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers: !APP_PROD ? [] : [],
      runtimeChecks: {
        strictActionImmutability: false,
        strictStateImmutability: false,
      },
    }),
    NxModule.forRoot(),
    StoreRouterConnectingModule.forRoot({ routerState: RouterState.Full }),
    EffectsModule.forRoot(effects),
    !APP_PROD ? StoreDevtoolsModule.instrument() : [],
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
