import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PublicGuard } from 'apps/kargoru-web/src/app/core/guards/public.guard';
import { PrivateGuard } from 'apps/kargoru-web/src/app/core/guards/private.guard';
import {MOBILE_FIX} from './core/constants/paths';
import {MobileFixComponent} from './shared/components/mobile-fix/mobile-fix.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [PublicGuard],
    canActivateChild: [PublicGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [PrivateGuard],
    canActivateChild: [PrivateGuard],
  },
  {
    path: MOBILE_FIX,
    component: MobileFixComponent,
    canActivate: [PrivateGuard],
  },
  {
    path: '**',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
